<template>
  <div v-if="getRole === 'systemoperator'">
    <div class="row">
      <div class="col-md-12">
        <h3>
          <span class="handwriting mr-2">Expert Analytics</span>
          {{ campaignStats.name }}
        </h3>
        <small
          class="pull-right d-block"
          v-if="campaignStats.queryTime && getRole == 'systemoperator'"
        >Query time: {{ (campaignStats.queryTime / 1000).toFixed(1) }}s</small>
      </div>
    </div>
    <div class="row" v-if="!error">
      <div class="col align-self-center">
        <button
          type="submit"
          class="btn btn-secondary btn-sm editable-submit"
          v-on:click="downloadCSV"
        >Download CSV</button>
      </div>
      <div class="col-md-auto">
        <b-dropdown
          variant="outline-white"
          :html="'<i class=\'fa '+activeSubscriptionsIcon+'\' aria-hidden=\'true\'></i> '+$t('labels.subscrivestats')"
          class="reportings-box my-1 pull-right"
        >
          <b-dropdown-item @click.native.capture.stop v-for="(item, index) in statisticsSubscription" :key="index">
            <b-form-checkbox v-model="item.status" name="check-button">{{item.text}}</b-form-checkbox>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-text style="width: 240px;">{{ $t("messages.reportingsrecipient", { email: userEmail }) }}</b-dropdown-text>
        </b-dropdown>
      </div>
      <div class="col-md-4">
        <DateRangePickerCustom opens="left" @update="reloadStats" :minDate="minAllowedDate" />
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-md-12 text-center">
        <b-alert v-if="error && !loading" show variant="danger">{{ error }}</b-alert>
      </div>
    </div>
    <div class="row" v-if="!error">
      
      <div v-if="loading" class="flip-square-loader"></div>

      <div v-if="!loading && !error" class="col-md-12">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="row">
              <div class="col-lg-6 grid-margin stretch-card">
                <div class="card bg-primary text-white border-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <i class="icon-user icon-lg"></i>
                      <div class="ml-4">
                        <h4 class="font-weight-light">Unique Users</h4>
                        <h3
                          class="font-weight-light"
                        >{{ $n(campaignStats.uniqueCount) }}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 grid-margin stretch-card">
                <div class="card bg-danger text-white border-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <i class="icon-screen-desktop icon-lg"></i>
                      <div class="ml-4">
                        <h4 class="font-weight-light">Impressions</h4>
                        <h3 class="font-weight-light">
                          {{
                          $n(campaignStats.impressionCount)
                          }}
                          <span v-if="campaignStats.impressionCountExpected > 0"
                            style="font-size: 0.5em;"
                          >({{ $n(campaignStats.impressionCountExpected) }})</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 grid-margin stretch-card">
                <div class="card bg-success text-white border-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <i class="icon-mouse icon-lg"></i>
                      <div class="ml-4">
                        <h4 class="font-weight-light">Clicks</h4>
                        <h3
                          class="font-weight-light"
                        >{{ $n(campaignStats.clickCount) }}
                          <span v-if="campaignStats.clickCountExpected > 0"
                            style="font-size: 0.5em;"
                          >({{ $n(campaignStats.clickCountExpected) }})</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 grid-margin stretch-card">
                <div class="card bg-dark text-white border-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <i class="icon-handbag icon-lg"></i>
                      <div class="ml-4">
                        <h4 class="font-weight-light">Site CR</h4>
                        <h3 class="font-weight-light">
                          {{ $n(campaignStats.siteCr, "percent") }}
                          <span
                            style="font-size: 0.5em;"
                          >({{ campaignStats.basketavg }}€)</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <small class="pull-right">
                  {{ $n(campaignStats.pixelCount) }} events /
                  {{ $n(campaignStats.uniqueCount) }} uniques
                </small>
                <h4 class="card-title">Onsite Analytics</h4>
                <hr />
                <div class="row">
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=cart`"
                          style="cursor: pointer"
                        >Cart</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.cartCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-danger"
                          role="progressbar"
                          :style="'width: ' + campaignStats.cartPerc + '%'"
                          :aria-valuenow="campaignStats.cartPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=catalog`"
                          style="cursor: pointer"
                        >Catalog</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.catalogCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-success"
                          role="progressbar"
                          :style="'width: ' + campaignStats.catalogPerc + '%'"
                          :aria-valuenow="campaignStats.catalogPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=checkout`"
                          style="cursor: pointer"
                        >Checkout</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.checkoutCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          :style="'width: ' + campaignStats.checkoutPerc + '%'"
                          :aria-valuenow="campaignStats.checkoutPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=front`"
                          style="cursor: pointer"
                        >Front</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.frontCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-warning"
                          role="progressbar"
                          :style="'width: ' + campaignStats.frontPerc + '%'"
                          :aria-valuenow="campaignStats.frontPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=generic`"
                          style="cursor: pointer"
                        >Generic</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.genericCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-danger"
                          role="progressbar"
                          :style="'width: ' + campaignStats.genericPerc + '%'"
                          :aria-valuenow="campaignStats.genericPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=order`"
                          style="cursor: pointer"
                        >Order</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.orderCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          :style="'width: ' + campaignStats.orderPerc + '%'"
                          :aria-valuenow="campaignStats.orderPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=product`"
                          style="cursor: pointer"
                        >Product</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.productCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-warning"
                          role="progressbar"
                          :style="'width: ' + campaignStats.productPerc + '%'"
                          :aria-valuenow="campaignStats.productPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=search`"
                          style="cursor: pointer"
                        >Search</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.searchCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-danger"
                          role="progressbar"
                          :style="'width: ' + campaignStats.searchPerc + '%'"
                          :aria-valuenow="campaignStats.searchPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <p class="d-flex mb-2">
                        <router-link
                          :to="`/events?campaign=${this.id}&cls=wishlist`"
                          style="cursor: pointer"
                        >Wishlist</router-link>
                        <span class="ml-auto font-weight-bold">
                          {{
                          formatNumber(campaignStats.wishlistCount, "en-EN")
                          }}
                        </span>
                      </p>
                      <div class="progress progress-xs">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          :style="'width: ' + campaignStats.wishlistPerc + '%'"
                          :aria-valuenow="campaignStats.wishlistPerc"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="amountOfDays > 1">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Impressions - {{ campaignStats.name }}</h4>
                <line-chart :chart-data="impressionsRangeChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Clicks - {{ campaignStats.name }}</h4>
                <line-chart :chart-data="clicksRangeChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div v-if="getRole == 'systemoperator'" class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">OPR - {{ campaignStats.name }}</h4>
                <line-chart :chart-data="oprRangeChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Default Orders - {{ campaignStats.name }}</h4>
                <line-chart :chart-data="orderRangeChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Publisher Conversions - {{ campaignStats.name }}</h4>
                <line-chart :chart-data="conversionRangeChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Revenue - {{ campaignStats.name }}</h4>
                <line-chart :chart-data="revenueRangeChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Product page views - {{ campaignStats.name }}</h4>
                <line-chart :chart-data="productsRangeChart" :options="chartOptions" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Impressions per hour</h4>
                <line-chart :chart-data="impressionsChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Clicks per hour</h4>
                <line-chart :chart-data="clicksChart" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div v-if="getRole == 'systemoperator'" class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">OPR per hour</h4>
                <line-chart :chart-data="oprChart" :options="chartOptions" />
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-for="(contract, cindex) in campaignStats.creativeArray" :key="cindex">
          <div class="col-md-12 grid-margin stretch-card" v-if="contract.entity">
            <div class="card">
              <div class="card-body">
                
                <div
                  class="table-responsive"
                  v-for="(channel, chindex) in contract.channels"
                  :key="chindex"
                >

                <h4 class="card-title">
                  Creative Performance {{contract.lineItem.name.toUpperCase()}} <br><br>
                  <img :src="getChannelImage(chindex)" height="30" :title="`${chindex}`">
                  
                  <!-- (Publisher {{
                  contract.entity && contract.entity.publisherObject && contract.entity.publisherObject.name
                  ? `"${contract.entity.publisherObject.name}"`
                  : `N/A`
                  }}) -->
                </h4>
                <!-- <p class="text-muted font-weight-light">We have 12 new projects from USA this month in Web Applications</p> -->
                
                  <!-- <h6><img class="mr-2" style="width: 20px; height: 20px;" :title="chindex" :src="`/images/tools/${chindex}.png`"></h6> -->

                  <table class="table mt-4">
                    <thead>
                      <tr>
                        <th class="pt-1 pl-0">Creative</th>
                        <th class="pt-1">Impressions</th>
                        <th class="pt-1">Clicks</th>
                        <th class="pt-1">PC Conv</th>
                        <th class="pt-1">PV Conv</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(creative, index) in channel.creatives" :key="index">
                        <td class="py-1 pl-0">
                          <div class="d-flex align-items-center">
                            <a
                              target="_blank"
                              :href="`https://${adserver}/banner?id=${creative && creative.entity ? creative.entity._id : ''}&c=${contract && contract.entity ? contract.entity._id : ''}&desc=campaignstats`"
                              style="cursor: pointer"
                            >
                              <i class="icon-picture" style="font-size: 1.5em;"></i>
                            </a>

                            <div class="ml-3">
                              <p class="mb-2">{{ creative.entity.name }}</p>
                              <p class="mb-0 text-muted text-small">
                                {{ creative.entity.width }}x{{
                                creative.entity.height
                                }}
                                - ID: {{ creative.entity._id }}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>{{ $n(creative.data.impression) }}</td>
                        <td>{{ $n(creative.data.click) }}</td>
                        <td>{{ $n(creative.data.pcConversions) }} ({{ $n(creative.data.pcConversionsValue, "currency") }} )</td>
                        <td>{{ $n(creative.data.pvConversions) }} ({{ $n(creative.data.pvConversionsValue, "currency") }} )</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{formatNumber(channelSum(channel, "impression"), "en-EN")}}</td>
                        <td>{{formatNumber(channelSum(channel, "click"), "en-EN")}}</td>
                        <td>{{formatNumber(channelSum(channel, "pcConversions"), "en-EN")}} ({{formatNumber(channelSum(channel, "pcConversionsValue"), "en-EN")}}€)</td>
                        <td>{{formatNumber(channelSum(channel, "pvConversions"), "en-EN")}} ({{formatNumber(channelSum(channel, "pvConversionsValue"), "en-EN")}}€)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
        >
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Dwell Time</h4>
                <line-chart :chart-data="dwellChart" :options="chartOptions" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="getRole == 'systemoperator'">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Campaign Assets</h4>
                  <!-- <p class="text-muted font-weight-light">We have 12 new projects from USA this month in Web Applications</p> -->
                  <div
                    class="table-responsive"
                    v-for="(assetArray, index) in campaignStats.assets"
                    :key="index"
                  >
                    <table class="table mt-4">
                      <thead>
                        <tr>
                          <th class="pt-1 pl-0" style="width: 80%">{{ index.toUpperCase() }}</th>
                          <th class="pt-1">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(asset, aindex) in assetArray" :key="aindex">
                          <td>{{ asset.name || asset.host || asset._id }}</td>
                          <td>
                            <i
                              class="fa fa-copy"
                              :title="'Copy ID to clipboard: ' + asset._id"
                              style="cursor: pointer"
                              @click="copyIdToClipboard(asset._id)"
                            ></i>
                            <a class="ml-2" :href="`/${index}/edit/${asset._id}`">
                              <i class="fa fa-pencil"></i>
                            </a>
                            <a class="ml-2" v-if="getRole === 'systemoperator' && asset.externalIds && asset.externalIds.p161" target="_blank" :href="`https://uiv5.platform161.com/#/${campaignStats.p161instance}/app/${index == 'banner' ? 'creative' : index == 'lineItem' ? 'campaign' : ''}/detail/${asset.externalIds.p161}`">
                              <i class="fa fa-external-link"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-alert show variant="danger">You are not allowed to access this page</b-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import LineChart from "../../charts/Line";
import defaultChannels from "@/constants/channels.js";

export default {
  name: "CampaignStats",
  data() {
    return {
      minAllowedDate: new Date(),
      statisticsSubscription: [
        { text: "Daily", value: "daily", oldstatus: false, status: false },
        { text: "Weekly", value: "weekly", oldstatus: false,  status: false },
        { text: "Monthly", value: "monthly", oldstatus: false,  status: false }
      ],
      updatingSubscriptions: false,
      topRefs: [
        "ebay.com",
        "gmx.de",
        "m.heise.de",
        "daswetter.com",
        "fitforfun.de",
        "gehalt.de",
        "m.sportbild.bild.de",
        "sternzeichen.net",
        "freegames.net",
        "sat1.de",
        "cinema.de",
        "m.tvtoday.de",
        "newyorker.com",
        "my.yahoo.com",
        "kaufda.de",
        "freenet.de",
        "spiele-kostenlos-online.de",
        "elektronik-kompendium.de",
        "gartenjournal.net",
        "sportdeutschland.tv",
        "weltderwunder.de",
        "antenne1.de",
        "apotheken-wissen.de",
        "bbheute.de",
        "ka-news.de",
        "hausgarten.net/gartenforum",
        "sportbuzzer.de",
        "kimovil.com",
        "dacianer.de",
        "english-4u.de",
        "forocoches.com",
        "freshideen.com",
        "ibancalculator.com",
        "appreviewed.net",
        "sprueche-wuensche.de",
        "animecharactersdatabase.com",
        "buienradar.nl",
        "goldseiten.de",
        "resourcepacks24.de",
        "sonyalpharumors.com",
        "sportpirate.com",
        "weltrezept.com",
        "forums.realgm.com",
        "home.meinestadt.de",
        "inautia.de",
        "stylebook.de",
        "esquire.de",
        "microsoft-programmierer.de",
        "spruch-und-wunsch.de",
        "hearthstoneheroes.de",
        "hausfrauenseite.de",
        "szene38.de",
        "radiosaw.de",
        "a3-freunde.de",
        "tag24.de",
        "android-user.de",
        "esslinger-zeitung.de",
        "scinexx.de",
        "rpr1.de",
        "torfabrik.de",
        "allgemeine-zeitung.de",
        "northdata.de",
        "dealdoktor.de",
        "gutezitate.com",
        "freizeitpartnerboerse.com",
        "filmdienst.de",
        "hochzeitsforum.de",
        "netzkino.de",
        "mamiweb.de",
        "jumk.de",
        "main-echo.de",
        "soccergaming.com",
        "anwalt.org",
        "kinder-malvorlagen.com",
        "minecraft-bauideen.de",
        "naehfrosch.de",
        "kraeuter-buch.de",
        "wer-weiss-was.de",
        "immosuchmaschine.de",
        "teckbote.de",
        "tuningblog.eu",
        "babynames.com",
        "ebay.ch",
        "gamerevolution.com",
        "geo.tv",
        "meetmeonline.de",
        "browsergames.de",
        "eurosport.fr",
        "plakos.de",
        "24hamburg.de",
        "bestgames.com",
        "china-gadgets.de",
        "mariospielespielen.de",
        "drohnen-forum.de",
        "isar-mami.de",
        "coolis.de",
        "boxnewsbox.com",
        "geizhals.at",
        "holidaycheck.de",
        "itopnews.de",
        "zergportal.de",
        "ecommerce-vision.de",
        "fahrradreparatur.net",
        "hunde-fan.de",
        "vegaliferocks.de",
        "zweisam.de",
        "basketball.eurobasket.com",
        "seen.de",
        "aufenglisch.net",
        "geburtstagssprueche.org",
        "worldescapegames.com",
        "bauen-und-heimwerken.de",
        "gartenbob.de",
        "hanauer.de",
        "horoskop2020.guru",
        "beste-freundin-gesucht.de",
        "tippsundtricks24.de",
        "iphone-tricks.de",
        "brettspielwelt.de",
        "nnz-online.de"
      ],
      currentDaterange: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  props: ["id"],
  mounted() {
    this.$root.$on('bv::dropdown::hide', () => {
      // check if changed
      let save = false;
      for(let s = 0; s < this.statisticsSubscription.length; s++){
        if(this.statisticsSubscription[s].status != this.statisticsSubscription[s].oldstatus){
          this.statisticsSubscription[s].oldstatus = this.statisticsSubscription[s].status;
          save = true;
          break;
        }
      }

      if(save){
        this.saveStatisticsSubscription();
      }
    })
  },
  created() {
    this.minAllowedDate.setDate(this.minAllowedDate.getDate() - 1);
    this.minAllowedDate.setMonth(this.minAllowedDate.getMonth() - 6) // allow only up to 6 months

    this.currentDaterange = this.dateRange;

    this.reloadStats();

    const userSubscriptions = this.subscriptions;

    if(Array.isArray(userSubscriptions)){
      for(let s = 0; s < userSubscriptions.length; s++){
        if(userSubscriptions[s].campaign == this.id){
          if(userSubscriptions[s].daily){
            this.statisticsSubscription[0].status = true;
            this.statisticsSubscription[0].oldstatus = true;
          }
          if(userSubscriptions[s].weekly){
            this.statisticsSubscription[1].status = true;
            this.statisticsSubscription[1].oldstatus = true;
          }
          if(userSubscriptions[s].monthly){
            this.statisticsSubscription[2].status = true;
            this.statisticsSubscription[2].oldstatus = true;
          }

          break;
        }
      }
    }
  },
  methods: {
    getChannelImage(key){
      return defaultChannels?.find(channel => channel.key == key)?.image
    },
    channelSum(channel, column) {
      let sum = 0;
      if(channel.creatives) {
        // eslint-disable-next-line
        for (const [key, creative] of Object.entries(channel.creatives)) {
          /* console.log(key, creative, column) */
          sum += creative.data[column];
        }
      }
      return sum;
    },
    pickerDate(picker){
      let str = picker.startDate ? picker.startDate.formatDate(true) : null;
      const endStr = picker.endDate ? picker.endDate.formatDate(true) : null;

      if(str !== endStr){
        str += ' - '+endStr;
      }

      return str;
    },
    async saveStatisticsSubscription(){
      this.updatingSubscriptions = true;

      let userSubscriptions = this.subscriptions;

      let append = true;
      if(Array.isArray(userSubscriptions)){

        for(let s = 0; s < userSubscriptions.length; s++){
          if(userSubscriptions[s].campaign == this.id){

            userSubscriptions[s].daily = this.statisticsSubscription[0].status,
            userSubscriptions[s].weekly = this.statisticsSubscription[1].status,
            userSubscriptions[s].monthly = this.statisticsSubscription[2].status

            append = false;
            break;
          }
        }
      }
      else{
        userSubscriptions = [];
      }

      if(append){
        userSubscriptions.push({
          campaign: this.id,
          daily: this.statisticsSubscription[0].status,
          weekly: this.statisticsSubscription[1].status,
          monthly: this.statisticsSubscription[2].status
        });
      }

      const payload = {
        email: this.userEmail,
        subscriptions: userSubscriptions,
        setResultAsCurrentUser: true,
      };

      await this.$store.dispatch("updateUser", payload);

      this.subscriptions = userSubscriptions;

      this.statisticsSubscription[0].oldstatus = this.statisticsSubscription[0].status;
      this.statisticsSubscription[1].oldstatus = this.statisticsSubscription[1].status;
      this.statisticsSubscription[2].oldstatus = this.statisticsSubscription[2].status;

      this.updatingSubscriptions = false;
    },
    copyIdToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    formatNumber(number, format) {
      return new Intl.NumberFormat(format).format(number);
    },
    reloadStats() { 
      this.$store.dispatch("getCampaignStats", {
        id: this.id,
        dateRange: this.dateRange
      });
    },
    downloadCSV() {
      let { startDate, endDate } = this.dateRange;
      if (typeof startDate == "number") startDate = new Date(startDate);
      if (typeof endDate == "number") endDate = new Date(endDate);

      let csv = "Date,Impressions,Clicks,Conversion,Revenue,Average Basket";
      if(this.id == "61387bd636e3d200213c8439") csv = csv + ",Spend,CPO"
      csv = csv + "\r\n"
      if (
        this.impressionsRangeChart.datasets[0].data.length ==
          this.impressionsRangeChart.labels.length &&
        this.clicksRangeChart.datasets[0].data.length ==
          this.clicksRangeChart.labels.length &&
        this.conversionRangeChart.datasets[0].data.length ==
          this.conversionRangeChart.labels.length &&
        this.revenueRangeChart.datasets[0].data.length ==
          this.revenueRangeChart.labels.length
      ) {
        for (
          var i = 0;
          i < this.impressionsRangeChart.datasets[0].data.length;
          i++
        ) {
          csv += `${this.impressionsRangeChart.labels[i]},${this.impressionsRangeChart.datasets[0].data[i]},`;
          csv += `${this.clicksRangeChart.datasets[0].data[i]},`;
          csv += `${this.conversionRangeChart.datasets[0].data[i]},`;
          csv += `${this.revenueRangeChart.datasets[0].data[i].toFixed(2)},`;
          csv += this.conversionRangeChart.datasets[0].data[i] > 0 ? `${(this.revenueRangeChart.datasets[0].data[i] /
            this.conversionRangeChart.datasets[0].data[i]).toFixed(2)}` : `0`;
          if(this.id == "61387bd636e3d200213c8439") {
            csv += `,${(this.revenueRangeChart.datasets[0].data[i] * 18 / 100).toFixed(2)}`;
            csv += this.conversionRangeChart.datasets[0].data[i] > 0 ? `,${(this.revenueRangeChart.datasets[0].data[i] * 18 / 100 / this.conversionRangeChart.datasets[0].data[i]).toFixed(2) }` : `,0`;
          }
          csv += "\r\n";
        }
      }

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = `${this.campaignStats.name} (${
        this.id
      })_${startDate.formatDate()}-${endDate.formatDate()}.csv`;
      anchor.click();
    }
  },
  computed: {
    ...mapGetters([
      "campaignStats",
      "impressionsChart",
      "clicksChart",
      "oprChart",
      "dwellChart",
      "impressionsRangeChart",
      "clicksRangeChart",
      "oprRangeChart",
      "orderRangeChart",
      "conversionRangeChart",
      "revenueRangeChart",
      "productsRangeChart",
      "loading",
      "error",
      "success",
      "warning"
    ]),
    activeSubscriptionsIcon(){
      if(this.updatingSubscriptions){
        return 'fa-spinner fa-spin';
      }
      else{
        for(let s = 0; s < this.statisticsSubscription.length; s++){
          if(this.statisticsSubscription[s].oldstatus === true){
            return 'fa-bell';
          }
        }
        return 'fa-bell-o';
      }
    },
    userEmail() {
      return localStorage.getItem(process.env.VUE_APP_EMAIL);
    },
    adserver() {
      return process.env.VUE_APP_ADSERVER;
    },
    dateRange: {
      get() {
        return this.$store.state.store.dateRange;
      },
      set(value) {
        this.$store.dispatch("setDateRange", value);
      }
    },
    subscriptions: {
      get() {
        return this.$store.state.store.subscriptions;
      },
      set(value) {
        this.$store.dispatch("setSubscriptions", value);
      }
    },
    amountOfDays() {
      let startD = new Date(this.dateRange.startDate);
      let endD = new Date(this.dateRange.endDate);

      let aod = parseInt(
        (new Date(endD.getUTCFullYear(), endD.getUTCMonth(), endD.getUTCDate()) -
          new Date(startD.getUTCFullYear(), startD.getUTCMonth(), startD.getUTCDate())) /
          (1000 * 3600 * 24) +
          1
      );
      return aod;
    },
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE)
  },
  components: {
    DateRangePickerCustom,
    LineChart
  }
};
</script>

<style>
.reportings-box {
  border-radius: 0px;
  background-color: white;
  border-color: #cccccc;
}

.reportings-box .btn {
  border-radius: 0px;
  background-color: white;
  color: #495057;
  padding: 1rem;
  font-size: 16px;
}

.reportings-box .dropdown-item.active,
.reportings-box .dropdown-item:active {
  color: inherit;
}

.reportings-box .dropdown-menu{
  border-radius: 0px;
}

.reportings-box label{
  display: block;
  cursor: pointer;
}
</style>