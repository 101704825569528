<template>
  <div class="row">
    <div class="col-12 grid-margin">
      <div class="col-md-12 mb-4">
        <h3>{{ getTitle }}</h3>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveEntity">
                <div v-if="loading" class="flip-square-loader"></div>
                <div class="row" v-else>
                  <div class="col-md-12">
                    <div class="alert alert-danger" v-if="error">
                      {{ error }}
                    </div>

                    <h4 class="mb-4">Describe the campaign</h4>

                    <div class="form-group">
                      <label for="name">Enter a name for this wizard</label>
                      <p>
                        <small
                          >This is not going to be the campaign name but helps
                          you identify this wizard in the overview later
                          on.</small
                        >
                      </p>
                      <input
                        v-model="name"
                        type="text"
                        class="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter a name for this wizard"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="adserver"
                        >Enter the FQDN of the pixel that is implemented on the
                        customer's website</label
                      >
                      <p>
                        <small
                          >No protocol, just the FQDN (e.g.
                          trk.adserver.com)</small
                        >
                      </p>
                      <input
                        v-model="adserver"
                        type="text"
                        class="form-control"
                        name="adserver"
                        id="adserver"
                        placeholder="Enter the FQDN of the pixel"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="advertiser">Advertiser</label>
                      <p>
                        <small
                          >Choose the advertiser that this campaign shall belong
                          to or
                          <router-link to="/advertiser/new"
                            >create new one</router-link
                          >.</small
                        >
                      </p>

                      <v-select
                        class="form-control"
                        v-model="advertiser"
                        name="advertiser"
                        id="advertiser"
                        required
                        :options="getAdvertisers"
                      ></v-select>
                    </div>

                    <div class="form-group">
                      <label for="vertical">Verticals</label>
                      <p>
                        <small
                          >Choose the most suitable verticals for this campaign
                          or
                          <router-link to="/vertical/new"
                            >create new one</router-link
                          >.</small
                        >
                      </p>

                      <v-select
                        class="form-control"
                        name="vertical"
                        v-model="vertical"
                        :value="verticalPreSelect"
                        id="vertical"
                        required
                        :options="getVerticals"
                      ></v-select>
                    </div>

                    <div class="form-group">
                      <label for="country">Countries</label>
                      <p>
                        <small
                          >Select the country in which the campaign is supposed
                          to be promoted.</small
                        >
                      </p>

                      <v-select
                        class="form-control"
                        name="country"
                        id="country"
                        v-model="country"
                        required
                        :options="getCountries"
                      ></v-select>
                    </div>

                    <div class="form-group">
                      <label for="url">Page URL</label>
                      <p>
                        <small
                          >Go to the advertisers website, copy the base url -
                          such as https://www.example.com/ - leave out
                          everything that comes after the base url.</small
                        >
                      </p>
                      <input
                        v-model="url"
                        type="url"
                        class="form-control"
                        name="url"
                        id="url"
                        placeholder="Enter the URL of the campaign"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="revenueSource">Revenue Source</label>
                      <p>
                        <small
                          >Please select the appropriate Affiliate Network for
                          this campaign, or select "Direct" for direct
                          cooperations with advertisers. Please contact the
                          administrator if the right network is missing.</small
                        >
                      </p>
                      <v-select
                        class="form-control"
                        name="revenueSource"
                        id="revenueSource"
                        v-model="revenueSource"
                        required
                        :options="getRevenueSources"
                      ></v-select>
                    </div>

                    <div class="form-group">
                      <label for="programid">ID of Partner Program</label>
                      <p>
                        <small
                          >Login to the Affiliate Network and find out the ID of
                          the partner program of this campaign. If this is a
                          direct cooperation, please enter "direct".</small
                        >
                      </p>
                      <input
                        v-model="programid"
                        type="text"
                        class="form-control"
                        name="programid"
                        id="programid"
                        placeholder="Enter the ID of partner program"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="affiliatelink">Example Affiliate Link</label>
                      <p>
                        <small
                          >Login to the Affiliate Network and copy&paste an
                          example affiliate link for this product. Can be a link
                          from a banner or the first entry in the product
                          catalog, etc. Make sure the link works and does not
                          lead to a Page Not Found site or similar.</small
                        >
                      </p>
                      <input
                        v-model="affiliatelink"
                        type="url"
                        class="form-control"
                        name="affiliatelink"
                        id="affiliatelink"
                        placeholder="An example affiliate link for this campaign"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="targeturl">Target URL</label>
                      <p>
                        <small
                          >When you follow the affiliate link from above, on
                          what site do you end up? Make sure you copy the exact
                          url from your browser, including all the url query
                          parameters.</small
                        >
                      </p>
                      <input
                        v-model="targeturl"
                        type="url"
                        class="form-control"
                        name="targeturl"
                        id="targeturl"
                        placeholder="The target url where the affiliate link takes you (incl all query parameters)"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="datasource">Product Data Source</label>
                      <p>
                        <small
                          >Please enter the download link, for the product data
                          feed. If the feeds need to be crawled automatically,
                          please enter "automatic". If product data has to be
                          added manually, please enter "manual".</small
                        >
                      </p>
                      <input
                        v-model="datasource"
                        type="text"
                        class="form-control"
                        name="datasource"
                        id="datasource"
                        placeholder="Enter the URL of the product feed. Alternatively enter automatic or manual."
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="affiliatelink"
                        >Affiliate Prefix (ONLY IF DATASOURCE ==
                        AUTOMATIC)</label
                      >
                      <p>
                        <small
                          >Enter an affiliate deeplink prefix for automated feed
                          crawling, e.g.
                          https://www.affiliatenetwork.com/?id=1234&url=</small
                        >
                      </p>
                      <input
                        v-model="affiliateprefix"
                        type="url"
                        class="form-control"
                        name="affiliateprefix"
                        id="affiliateprefix"
                        placeholder="An affiliate deeplink prefix for this campaign"
                      />
                    </div>

                    <div class="form-group">
                      <label for="datatype">Data Type</label>
                      <p>
                        <small
                          >Please select the format of the product feed. If you
                          entered automatic or manual above, leave this at
                          CSV.</small
                        >
                      </p>

                      <v-select
                        class="form-control"
                        name="datatype"
                        id="datatype"
                        v-model="datatype"
                        required
                        :options="getDatatypes"
                      ></v-select>
                    </div>

                    <div class="form-group">
                      <label for="compression">Compression</label>
                      <p>
                        <small
                          >Please select how the Product Data Source download is
                          compressed.</small
                        >
                      </p>

                      <v-select
                        class="form-control"
                        name="compression"
                        id="compression"
                        v-model="compression"
                        required
                        :options="getCompressions"
                      ></v-select>
                    </div>

                    <div class="form-group">
                      <label for="delimiter"
                        >CSV delimiter / XML selector</label
                      >
                      <p>
                        <small
                          >Enter a string that is used as a column delimiter in
                          the CSV file or the direct selector in an XML file
                          (such as "," in CSV or "item" in XML). If the CSV file
                          is tab separated, enter "tab".</small
                        >
                      </p>
                      <input
                        v-model="delimiter"
                        type="text"
                        class="form-control"
                        name="delimiter"
                        id="delimiter"
                        placeholder="Enter a string that is used as a column delimiter in the CSV file."
                        required
                      />
                    </div>

                    <h4 class="mb-4 mt-5">Add a creative</h4>

                    <div class="form-group">
                      <label for="category">Banner Content Categories</label>
                      <p>
                        <small
                          >Select all appropriate content categories for this
                          banner. Make sure you choose the right ones, as this
                          might greatly impact ad delivery.</small
                        >
                      </p>

                      <v-select
                        class="form-control"
                        name="category"
                        id="category"
                        required
                        v-model="category"
                        multiple
                        :options="getCategories"
                      ></v-select>
                    </div>

                    <label>Banner Format</label>
                    <p>
                      <small
                        >Enter the format of the banner in pixels. If you don't
                        have a banner available, enter "300" for width and "250"
                        for height.</small
                      >
                    </p>
                    <ul class="list-inline margin-bottom-0">
                      <li class="form-group">
                        <input
                          v-model="width"
                          type="number"
                          class="form-control"
                          id="width"
                          name="width"
                          placeholder="Width"
                          required
                        />
                      </li>
                      <li class="form-group">
                        <input
                          v-model="height"
                          type="number"
                          class="form-control"
                          id="height"
                          name="height"
                          placeholder="Height"
                          required
                        />
                      </li>
                    </ul>

                    <div class="form-group">
                      <label for="adcode">Banner Ad Code</label>
                      <p>
                        <small
                          >Enter the banner ad code for the first creative.
                          Ideally choose a 300x250 pixel creative and copy&paste
                          the ad code from the affiliate network. If this is not
                          available, leave the default value unchanged.</small
                        >
                      </p>
                      <prism-editor
                        class="form-control"
                        v-model="adcode"
                        name="adcode"
                        id="adcode"
                        :highlight="highlighter"
                        line-numbers
                      ></prism-editor>
                    </div>

                    <h4 class="mb-4 mt-5">Line Item</h4>

                    <div class="form-group">
                      <label for="channel">Create a Line Item with this channel</label>
                      <v-select
                        class="form-control"
                        name="channel"
                        id="channel"
                        required
                        v-model="channel"
                        :options="getChannels"
                        :reduce="(c) => c.code"
                      ></v-select>
                    </div>

                    <br /><br />

                    <button
                      type="submit"
                      class="btn btn-primary mr-2"
                    >
                      Save
                    </button>
                    <button class="btn btn-light" @click="goToEntities">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
import revenueSources from "@/constants/revenueSources.js";
import CHANNELS from "@/constants/channels.js";

const routeName = "/wizards";
// const backendRoute = "wizard";
const entityName = "Wizard";

export default {
  name: `${entityName}Form`,
  components: {
    PrismEditor,
  },
  props: ["id"],
  data() {
    return {
      advertisers: [],
      countries: [],
      verticals: [],
      categories: [],
      adcode: `<!-- Replace clicklink with {{clickTracker}}{{fallbackUrlEncoded}} -->\n`,
      affiliatelink: null,
      height: 250,
      width: 300,
      affiliateprefix: null,
      programid: null,
      targeturl: null,
      datasource: null,
      revenueSource: null,
      datatype: { label: "CSV", code: "csv" },
      datatypes: [
        { label: "CSV", code: "csv" },
        { label: "XML", code: "xml" },
      ],
      compression: { label: "None", code: "feed" },
      compressions: [
        { label: "None", code: "feed" },
        { label: "ZIP", code: "zip" },
        { label: "GZIP", code: "gz" },
        { label: "TAR", code: "tar" },
        { label: "TAR.GZ", code: "tar.gz" },
      ],
      country: null,
      vertical: null,
      verticalPreSelect: null,
      delimiter: ',',
      category: null,
      advertiser: null,
      entityName: entityName,
      name: null,
      adserver: process.env.VUE_APP_ADSERVER,
      url: "https://",
      channel: 'cyrl',
    };
  },
  async created() {
    this.$store.dispatch("setLoadingActive");

    let response = (await this.$api.advertiser.getAll()).items;
    for (var p in response) {
      this.advertisers.push({
        label: response[p].name,
        code: response[p]._id.toString(),
        url: response[p].url
      });
    }

    response = (await this.$api.vertical.getAll()).items;

    for (var q in response) {
      this.verticals.push({
        label: response[q].name,
        code: response[q]._id.toString(),
      });
    }

    response = await this.$api.targeting.getCountries();

    for (var w in response) {
      this.countries.push({
        label: response[w].name,
        code: response[w].alpha2,
      });
    }

    response = await this.$api.targeting.getCategories();

    for (var e in response) {
      this.categories.push({
        label: response[e].attributes.name,
        code: response[e].id + "|||" + response[e].attributes.code,
      });
    }

    if (this.id) {
      response = await this.$api.wizard.get(this.id);

      this.name = response.name;
      this.adserver = response.adserver;
      this.advertiser = this.advertisers.find(
        (x) => x.code == response.advertiser
      );
      this.country = this.countries.find((x) => x.code == response.countries);
      this.vertical = this.verticals.find((x) =>
        response.vertical.includes(x.code)
      );
      this.url = response.url;
      this.revenueSource = revenueSources.find(
        (x) => x.code == response.revenueSource
      );
      this.programid = response.programid;
      this.affiliatelink = response.affiliatelink;
      this.affiliateprefix = response.affiliateprefix;
      this.targeturl = response.targeturl;
      this.datasource = response.datasource;
      this.datatype = this.datatypes.find((x) => x.code == response.datatype);
      this.compression = this.compressions.find(
        (x) => x.code == response.compression
      );
      this.delimiter = response.delimiter;
      this.adcode = response.adcode;
      this.category = this.categories.filter((x) =>
        response.categories.includes(x.code)
      );
      this.width = response.width;
      this.height = response.height;

      if(response.channel){
        this.channel = response.channel;
      }
      else{
        this.channel = 'platform161';
      }
    }
    this.$store.dispatch("setLoadingInactive");
  },
  computed: {
    ...mapGetters(["loading", "error"]),
    getAdvertisers() {
      return this.advertisers;
    },
    getCountries() {
      return this.countries;
    },
    getVerticals() {
      return this.verticals;
    },
    getCompressions() {
      return this.compressions;
    },
    getCategories() {
      return this.categories;
    },
    getChannels() {
      const suitableChannels = CHANNELS.filter(c => ['affiliate_cpc', 'platform161', 'cyrl'].includes(c.key));
      return suitableChannels.map(c => ({ label: c.name, code: c.key }));
    },
    getDatatypes() {
      return this.datatypes;
    },
    getRevenueSources() {
      return revenueSources;
    },
    getTitle() {
      return this.id ? `Edit ${entityName}` : `Create a new ${entityName}`;
    }
  },
  watch: {
    affiliatelink(){
      // if(this.revenueSource)
      //   this.preset();
    }
  },
  methods: {
    preset(){
      switch(this.revenueSource.code){
        case 'effiliation':   this.affiliateprefix = this.affiliatelink+'&url=';
                              this.adcode = `<a href="{{clickTracker}}${encodeURIComponent(this.affiliatelink)}" target="_blank">
  <img src="${this.affiliatelink.replace(/effi.click/, 'effi.show')}" alt="${this.name}"/>
</a>`;
                              break;

        case 'adtraction':    this.affiliateprefix = this.affiliatelink+'&url=';
                              this.adcode = `<a href="{{clickTracker}}${encodeURIComponent(this.affiliatelink)}" target="_blank">
  <img src="${this.affiliatelink.replace(/t=2/, 't=1')+'&i=1'}" alt="${this.name}"/>
</a>`;
                              break;
                              
        case 'belboon':       this.compression = { label: "ZIP", code: "zip" };
                              this.affiliateprefix = this.affiliatelink+'&rmd=3&trg=';
                              this.adcode = `<a href="{{clickTracker}}${encodeURIComponent(this.affiliatelink)}" target="_blank">
  <img src="${this.affiliatelink.replace(/tsc/, 'tsv').replace(/typ=r/, "")}" alt="${this.name}"/>
</a>`;
                              break;
                              
        case 'kwanko':        this.affiliateprefix = this.affiliatelink+'&redir=';
                              this.adcode = `<a href="{{clickTracker}}${encodeURIComponent(this.affiliatelink)}" target="_blank">
  <img src="${this.affiliatelink.replace(/\?/, '?a=').replace(/typ=r/, "")}" alt="${this.name}"/>
</a>`;
                              break;
      }
    },
    highlighter(code) {
      return highlight(code, languages.markup); //returns html
    },
    goToEntities() {
      this.$router.push(routeName);
    },
    async saveEntity() {
      this.$store.dispatch("setLoadingActive");
      try {
        const bodyData = {
          name: this.name,
          adserver: this.adserver,
          advertiser: this.advertiser?.code,
          countries: this.country? [this.country?.code] : [],
          vertical: [this.vertical?.code],
          url: this.url,
          revenueSource: this.revenueSource?.code,
          programid: this.programid,
          affiliatelink: this.affiliatelink,
          affiliateprefix: this.affiliateprefix,
          targeturl: this.targeturl,
          datasource: this.datasource,
          datatype: this.datatype?.code,
          compression: this.compression?.code,
          delimiter: this.delimiter,
          adcode: this.adcode,
          categories: this.category.map((x) => x.code),
          width: parseInt(this.width),
          height: parseInt(this.height),
          channel: this.channel,
        };

        if (this.id) {
          await this.$api.wizard.update(this.id, bodyData);
        } else {
          await this.$api.wizard.create(bodyData);
        }

        this.$store.dispatch("setLoadingInactive");
        this.$router.push(routeName);
        this.$store.dispatch("setError", "");
        this.$store.dispatch(
          "setSuccess",
          `${entityName} created successfully`
        );
      } catch (error) {
        
        this.$store.dispatch("setLoadingInactive");
      }
    },
  },
};
</script>

<style>
</style>